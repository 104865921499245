.app-container {
    display: grid;
    grid-template-columns: minmax(15%, 320px) 1fr;
    margin: 0;
    padding: 0;
}

.dashboard-container {
    display: grid;
    gap: 26px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(1fr, 3);
    grid-template-areas:
        'portfolios portfolios portfolios portfolios'
        'chart chart chart total'
        'chart chart chart refferal';
}

.admin-dashboard-container {
    display: grid;
    gap: 26px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
        'portfolios portfolios portfolios '
        'adminStatistic adminStatistic adminStatistic';
}

.emp-dashboard-container {
    display: grid;
    gap: 26px;
    grid-template-columns: 1fr 1fr;
    margin: 50px;
    grid-template-areas: 'adminStatistic adminStatistic';
}

.portfolio-grid-container {
    display: grid;
    gap: 0px 25px;
    grid-area: portfolios;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: 'p-w-1 p-w-2 p-w-3 p-w-4';

    &.admin-container {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .startup {
        grid-area: p-w-1;

        .item-header {
            background-color: #6438ab;
        }
    }
    .established {
        grid-area: p-w-2;

        .item-header {
            background-color: #aa3c78;
        }
    }
    .active {
        grid-area: p-w-3;

        .item-header {
            background-color: #3e86b0;
        }
    }
    .trader {
        grid-area: p-w-4;

        .item-header {
            background-color: #d5743b;
        }

        .users-list {
            height: 96px;
            overflow-y: scroll;
        }
    }

    .item-header {
        align-items: center;
        border-radius: 10px 10px 0 0;
        box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
        display: flex;
        height: 45px;
        justify-content: space-between;
        padding-left: 16px;
        padding-right: 14px;
        position: relative;
        width: 100%;

        .title {
            color: #f9f0eb;
            font-family: 'Rothek SemiBold';
            font-size: 1em;
            font-style: normal;
            letter-spacing: normal;
            z-index: 10;
        }

        .item-invest {
            align-items: center;
            border-radius: 5px;
            color: #ded7ea;
            cursor: default;
            display: flex;
            font-size: 1em;
            justify-content: center;
            line-height: 1;
            padding: 0px 5px;
            white-space: nowrap;
            z-index: 10;
        }

        img {
            left: 0;
            margin-left: auto;
            margin-right: auto;
            position: absolute;
            right: 0;
            text-align: center;
        }
    }

    .item-chart {
        @include flex-center;
        margin: 20px 0;
    }

    .item-widget {
        background-color: rgba($color: #2f3547, $alpha: 0.8);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
        padding: 20px;

        .widget-title {
            color: #ded7ea;
            font-size: 1em;
            margin-top: 8px;
            text-align: center;
        }
    }
}

.disabled-wrapper {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .primary-text {
        color: #fff;
        font-weight: 600;
    }
}

.chart {
    grid-area: chart;
}
.total {
    // display: grid;
    // grid-template-rows: auto 1fr;
    // justify-content: space-between;
    display: flex;
    flex-direction: column;
    grid-area: total;

    .total-price-wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .total-item {
            background-color: #0eaba2;
            background-image: linear-gradient(-63deg, #6c77b2 0%, #6c77b2 100%);
            border-radius: 10px 10px 0 0;
            box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
            padding: 16px;
            width: 100%;

            .total-primary-label {
                color: #fbf8f8;
                font-size: 2.25em;
                line-height: 1;
            }
            .total-secondary-label {
                color: #fbf8f8;
                font-family: 'Rothek SemiBold';
                font-size: 1em;
            }
        }
    }

    .total-bg {
        align-items: flex-end;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;

        img {
            width: 100%;
        }
    }
}
.refferal {
    background-color: black;
    grid-area: refferal;
}

.grid-card {
    height: 100%;
}

.month-toggle {
    color: #555863;
    font-family: 'Rothek SemiBold';
    font-size: 1em;
}

.income-dropdown {
    .dropdown-menu.show {
        background: #191d2a;
    }
}

.phone-menu {
    background: #191d2a;
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;

    .dropdown-item {
        text-align: start !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.charts-grid-container {
    display: grid;
    gap: 25px;
    grid-template-columns: auto 1fr;
    margin-top: 20px;

    .statistics-widget {
        background-color: #dde3f7;
        background-image: linear-gradient(-54deg, #dde3f7 0%, #ffffff 100%);
        border-radius: 10px;
        box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
        opacity: 0.95;

        rect::first-line {
            fill: rgba(0, 0, 0, 0) !important;
        }
    }

    .world-widget {
        @include flex-center;
        background-color: #3f92d9;
        background-image: linear-gradient(-63deg, #1d72f1 0%, #1ac5fb 100%);
        border-radius: 10px;
        box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
        opacity: 0.95;
        padding: 0 50px;
        rect {
            fill: rgba(0, 0, 0, 0);
            height: 100%;
            width: 100%;
        }
    }
}

.dashboard-chart-wrapper {
    background-color: rgba($color: #2f3547, $alpha: 0.8);
    border-radius: 10px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
    display: grid;
    grid-template-rows: 60px auto;
    overflow: auto;
    padding: 40px;

    .chart-header {
        align-items: center;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        .report-type {
            color: #fbf8f8;
            display: flex;
            font-family: 'Rothek SemiBold';
            font-size: 1em;
            gap: 6px;
            justify-content: flex-end;
        }

        .chart-portfolio-wrapper {
            align-items: center;
            display: flex;
            gap: 20px;
            justify-content: center;
            .portfolio-item {
                color: rgba($color: #ded7ea, $alpha: 0.3);
                cursor: pointer;
                font-family: 'Rothek SemiBold';
                font-size: 1em;

                &.active-item {
                    color: #ded7ea;
                }
            }
        }
        .chart-period-wrapper {
            .period-label {
                color: #8b92a8;
                font-size: 0.875em;
            }
            select {
                background-color: rgba(47, 53, 71, 1) !important;
            }

            .period-select {
                background-color: transparent;
                border-radius: 14px;
                border: 1px solid #4e556b;
                color: #8b92a8;
                font-size: 0.875em;
                margin-left: 8px;
                padding: 0.5rem 1rem;

                option {
                    background-color: transparent;
                }
            }
        }
    }

    .chart-wrapper {
        margin-top: 20px;
    }
}

.total-container {
    height: 100%;
    width: 100%;
}

.refferal-container {
    position: relative;
    background-color: #dde3f7;
    background-image: linear-gradient(-54deg, #dde3f7 0%, #ffffff 100%);
    border-radius: 10px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: fit-content;
    width: 100%;

    .widget-icon {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .refferal-info {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 1.2em;

        .info-wrapper {
            .info-percent {
                color: #555863;
                font-size: 2.25em;
                line-height: 1.375em;
            }

            .info-text {
                color: #555863;
                font-family: 'Rothek SemiBold';
                font-size: 1em;
            }
        }

        .people-header-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .info-text {
                color: #555863;
                font-family: 'Rothek SemiBold';
                font-size: 1em;
            }

            .flag-img {
                height: 20px;
                width: 34px;
            }
        }
    }

    .country-chart-wrapper {
        padding: 1.2em;
        display: flex;
        max-height: 200px;
        justify-content: space-between;
        flex-direction: row;

        .vertical-item {
            align-self: flex-end;
            width: 46px;
            height: auto;

            .chart-item {
                margin-top: 10px;
                height: 12px;
                width: 100%;
                border-radius: 6px;
                background-color: #1ad9fb;
                &:first-child {
                    background-color: transparent;
                }
            }

            .chart-month {
                margin-top: 8px;
                width: 100%;
                text-align: center;
                color: #858b9a;
                font-family: 'Rothek SemiBold';
                font-size: 12px;
            }
        }
    }

    .refferal-statistics {
        padding: 1.2em;
        .statistics-percentage {
            color: #555863;
            font-family: 'Rothek SemiBold';
            font-size: 0.875em;
            text-align: right;
        }
        .statistic-item-list {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .statistic-item {
            cursor: default;
            display: grid;
            gap: 12px;
            grid-template-columns: minmax(38px, 10%) auto;

            &:hover {
                .progress-view {
                    // background-color: #0a889d;
                }
            }
            &.active {
                .progress-view {
                    &.invest {
                        background-color: #0a889d;
                    }
                    &.payout {
                        background-color: #9770e4;
                    }
                }
            }

            .item-month {
                color: #8e94a4;
                font-family: 'Rothek SemiBold';
                font-size: 0.75em;
                text-align: end;
            }
            .item-progress {
                align-items: center;
                display: flex;
            }
            .progress-view {
                background-color: #c7ccdf;
                height: 7px;
            }
        }
    }
}

.modal-dialog {
    max-width: 70% !important;
}

.admin-statisics-grid-container {
    display: grid;
    gap: 0px 25px;
    grid-area: adminStatistic;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 20px;

    &.empl-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .statistic-item {
        background-color: rgba($color: #000000, $alpha: 0.3);
        border-radius: 10px;
        box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
        height: 187px;
        padding: 1.2rem;
        .primaryLabel {
            color: #fff;
            font-size: 2em;
        }

        .secondaryLabel {
            color: #fff;
            font-family: 'Rothek SemiBold';
            font-size: 1.5em;
        }
    }
}

.btn-bg {
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
    color: #fff;
}

.card-active {
    background-color: #0d6efd;
    color: white;
}

.withdrawal-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.referral-list {
    border: 1px solid black;
    margin: 20px 0;
    padding: 10px;

    .list-percentage {
        font-size: 1.5em;
        text-align: center;
    }

    .referral-item {
        align-items: center;
        border-width: 1px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin: 0 20px;

        .user-icon {
            @include flex-center;
            background-color: #666666;
            border-radius: 50%;
            height: 50px;
            width: 50px;

            span {
                color: white;
                font-weight: 600;
                text-transform: uppercase;
            }
        }
    }
}

// NEW
.bg-transparent {
    background: transparent;
}

.startup-title {
    color: #ded7ea;
    font-size: 16px;
    font-weight: 400;
}

.header-startup {
    background-color: #6438ab;
    border-radius: 10px 10px 0 0 !important;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
}

.header-established {
    background-color: #aa3c78;
    border-radius: 10px 10px 0 0 !important;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
}

.header-active {
    background-color: #3e86b0;
    border-radius: 10px 10px 0 0 !important;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
}

.header-trader {
    background-color: #d5743b;
    border-radius: 10px 10px 0 0 !important;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
}

.trade-bg {
    align-items: center;
    background-color: #00111f;
    background-image: url('../../images/widget-bg.png');
    background-repeat: repeat;
    background-size: cover;
    border-radius: 0 0 10px 10px;
    display: grid;
    height: auto;
    width: 100%;
}

.trade-in-progress-amount {
    color: #fbf8f8;
    font-size: 25px;
    font-weight: 300;
}

.trade-in-progress {
    // box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
    color: #0d9c96;
    font-size: 16px;
    font-weight: 400;
}

.waiting-trading-amount {
    color: #fbf8f8;
    font-size: 25px;
    font-weight: 300;
}

.waiting-trade {
    color: #d5743b;
    font-size: 16px;
    font-weight: 400;
}

.img-total-balance {
    height: 33px;
    width: 33px;
}

.CircularProgressbar .CircularProgressbar-text {
    fill: #ffffff !important;
    font-size: 15px;
    font-weight: 400;
}
.profit-item {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.4);
    border-radius: 43px;
    .profit-progress {
        width: 86px;
        height: 86px;
    }

    .profit-text-wrapper {
        flex: 1;
        align-items: center;
        text-align: center;

        .profit-text-primary {
            color: #ffffff;
            font-size: 21px;
            font-weight: 300;
        }
        .profit-text-secondary {
            margin-top: 5px;
            color: #ffffff;
            font-size: 9px;
            font-weight: 400;
            font-style: normal;
        }
    }
}

.income-icon {
    width: 12px;
    height: auto;
    margin-left: 8px;
}

.startup-disclaimer,
.established-disclaimer,
.active-disclaimer {
    display: flex;
    position: absolute;
    right: 0;
}

.progress-disclaimer {
    display: flex;
    position: absolute;
}

@include media-breakpoint-down(xs) {
    .dashboard-chart-wrapper {
        padding: 20px;

        .chart-header {
            margin: 0 20px;
            .chart-portfolio-wrapper {
                padding: 0 20px;
            }
        }
    }
}
