.app-not-work-container {
    height: 100vh;
    display: grid;
    place-items: center;
    color: #fff;
    background-image: url('./assets/images/background-dashboard.png');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* @media (max-width: 800px) {
    .app-not-work-container {
        background-image: url('./assets/images/release1mobil.png');
    }
} */

.app-not-work-container .text-wrapper {
    margin: 20px;
    text-align: center;
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 30px;
}

.app-container {
    min-height: 100vh;
    background-image: url('./assets/images/background-dashboard.png');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.not-found-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100vh;
    background-image: url('./assets/images/not-found-404.png');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-dashboard {
    background-image: url('./assets/images/background-dashboard.png');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.card {
    background-color: transparent;
    border: 0;
}
