.trades-container {
    .trade-form {
        background-color: rgba($color: #2f3547, $alpha: 0.8);
        border-radius: 10px;
        box-shadow: 0 5px 8px rgba(0, 0, 0, 0.3);
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(4, 1fr);
        margin-bottom: 16px;
        padding: 20px 12px;
        place-items: center;
    }

    .trade-item {
        background-color: rgba($color: #2f3547, $alpha: 0.8);
        border-radius: 10px;
        box-shadow: 0 5px 8px rgba(0, 0, 0, 0.3);
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(4, 1fr);
        margin-bottom: 16px;
        padding: 20px 12px;
        place-items: center;

        .item-text {
            color: #fff;
            font-size: 1em;
        }
    }
}
