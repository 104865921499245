.form-control:valid {
    background-color: transparent !important;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #585d72;
    color: #8b8e9c;
}

.form-control:focus {
    background-color: transparent !important;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-control.is-invalid:focus {
    box-shadow: none;
}

.form-control.is-valid:focus {
    box-shadow: none;
}

.form-label {
    color: #8b8e9c;
}

.form-floating > label {
    padding: 1rem 0;
}

.form-floating > .form-control,
.form-floating > .form-select {
    padding: 1rem 0.25rem;
    padding-right: 2rem;
}

.date-picker-wrapper {
    position: relative;
    margin-bottom: 8px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #585d72;
    color: #8b8e9c;
    -webkit-text-fill-color: #8b8e9c;
    --webkit-box-shadow: 0 0 0px 1000px #16122e inset;
    transition: background-color 5000s ease-in-out 0s;
}

.custom-checkbox {
    margin-top: 10px;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 10px;
}

.label-error {
    color: #dc3545;

    a {
        color: #dc3545;
    }
}

.input-group-text {
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: #8b8e9c;
    border-bottom: 2px solid #585d72;
}

.search-typehead {
    .dropdown-menu.show {
        background: rgba(0, 12, 40, 1);
    }
}
