.documents-container {
    margin: 8px;
    .tutorial-title {
        margin-top: 56px;
        color: #ffffff;
        font-size: 1.875em;
        font-weight: 700;
    }
}

.document-item {
    background-color: rgba($color: #242a3c, $alpha: 0.95);
    display: grid;
    grid-template-columns: 4px 1fr;
    border-top: 2px solid #2f364c;
    &:first-child {
        border-top: 2px solid #222839;
    }

    .item-divider {
        content: '';
        height: 100%;
        width: 4px;
    }

    .item-container {
        display: grid;
        grid-template-columns: 1fr 15%;
        padding: 2rem 2rem 2rem 1rem;

        .item-title {
            color: #ffffff;
            font-size: 1.25em;
        }

        .item-subtitle {
            color: #6e7487;
            font-size: 0.875em;
        }
        .item-description {
            color: #bfc2cd;
            font-size: 0.875em;
            margin-top: 1rem;
        }

        .item-btn-group {
            @include flex-center;
            gap: 20px;

            svg {
                fill: #80838c;
            }
        }
    }
}
