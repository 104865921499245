.terms-contaier {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: grid;
    place-items: center;
    z-index: 1024;

    .terms-card {
        background-color: #16122e;
        box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
        border-radius: 10px;
        opacity: 0.97;

        .terms-text {
            color: #fff;
        }

        .terms-btn {
            margin-left: 20px;
        }
    }
}
