.bonus-actual-profits {
    background-color: rgba(67, 76, 100, 0.7);
    border-radius: 10px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
    color: #fff;
    padding: 22px;
}

.bonus-actual-desc {
    color: #fff;
    font-size: 24px;
    font-weight: 300;
}

.bonus-profits-title {
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
}

.bonus-actual-title {
    color: #ffffff;
    font-size: 1rem;
    font-weight: thin;
}

.bonus-profit-amount,
.bonus-reinvest-amount {
    color: #fffefc;
    font-size: 2.25rem;
    font-weight: 400;
}

.reinvest-modal-button {
    align-items: center;
    display: flex;
    justify-content: center;
    .actual-reinvest-balance {
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
    }
    .sign-in-btn {
        min-width: auto;
    }
}

.reinvest-modal-dialog {
    .modal-content {
        background-color: rgba(47, 53, 71, 0.97) !important;
        border-radius: 10px;
    }
    .actual-reinvest-balance {
        color: #ffffff;
        font-size: 18px;
        font-weight: 600;
        white-space: nowrap;
        display: flex;
    }
}

.umud {
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    background-color: #101522;
    opacity: 0.95;
    .form-control:valid {
        border-bottom: 0;
    }
}

.reinvest-input-title {
    color: #a5a9b2;
    font-size: 14px;
    font-weight: 500;
    text-shadow: 0 2px 14px rgba(0, 0, 0, 0.75);
}
