.user-logo-wrapper {
    width: 100%;
    height: 100%;
    min-width: 50px;
    min-height: 50px;
    border: 2px solid #fff;
    border-radius: 50%;
    @include flex-center;

    .user-name {
        color: #fff;
        font-size: 1.5em;

        &.small-text {
            font-size: 1em;
        }
    }
}

.trader-item {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .trader-profile {
        display: flex;
        flex-direction: row;
    }
    .trader-img {
        width: 40px;
        height: 40px;
        margin-right: 8px;
    }

    .trader-info {
        .trader-primary-label {
            color: #ffffff;
            font-size: 1em;
        }

        .trader-secondary-label {
            color: #dbf9e1;
            font-size: 0.875em;
        }
    }
}

.modal-dialog {
    @include flex-center;
}

.modal-content {
    width: fit-content;
}

.carousel-indicators {
    display: none;
}

@include media-breakpoint-down(md) {
    .modal {
        .modal-dialog {
            margin: 0 auto;

            .modal-content {
                .auth-padding {
                    padding: 10px;
                }
            }
        }
    }
}
