.refferal-contaienr {
    margin: 50px;
}
.refferal-tree-grid-container {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
}
.refаeral-tree {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    .tree-first-column {
        @include flex-center;
        flex-direction: column;

        .user-name {
            color: #fffefc;
            font-size: 1em;
        }

        .border-out {
            border-radius: 50%;
            border: 1px solid rgba($color: #7b4fc1, $alpha: 0.4);
            padding: 10px;
        }

        .border-in {
            @include flex-center;
            background-color: rgba($color: #000000, $alpha: 0.3);
            border-radius: 50%;
            border: 5px solid #7b4fc1;
            box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
            height: 154px;
            width: 154px;
        }
    }

    .tree-second-column {
        color: #fffefc;
        display: grid;
        flex-direction: column;
        gap: 20px;
        grid-template-rows: 1fr 1fr;

        .border-out {
            @include flex-center;
            background-color: rgba($color: #000000, $alpha: 0.3);
            border-radius: 50%;
            border: 3px solid #aa3c78;
            box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
            height: 110px;
            padding: 10px;
            width: 110px;
        }

        .border-in {
            @include flex-center;
            border-radius: 50%;
            border: 1px solid rgba($color: #aa3c78, $alpha: 0.3);
            padding: 36px;
        }
    }
    .tree-third-column {
        color: #fffefc;
        display: grid;
        gap: 20px;
        grid-template-rows: repeat(3, 1fr);
        height: 100%;
        .tree-third-wrapper {
            display: grid;
            gap: 25px;
            grid-template-columns: 4fr 1fr;

            .border-out {
                @include flex-center;
                background-color: rgba($color: #000000, $alpha: 0.3);
                border-radius: 50%;
                border: 3px solid #02c5f1;
                box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
                height: 80px;
                padding: 10px;
                width: 80px;
            }

            .border-in {
                @include flex-center;
                border-radius: 50%;
                border: 1px solid rgba($color: #02c5f1, $alpha: 0.3);
                padding: 26px;
            }

            .circle-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                width: 100%;
            }

            .small-circle {
                border-radius: 50%;
                border: 1px solid #9499ac;
                height: 14px;
                width: 14px;
            }
        }
    }

    .tree-fourth-column {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        height: 100%;

        .circle-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
        }

        .small-circle {
            border-radius: 50%;
            border: 1px solid #9499ac;
            height: 14px;
            width: 14px;
        }
    }
}
.refferal-info {
    @include flex-center;
    padding: 20px;
    p {
        color: #fffefc;
        font-size: 1.125em;
        font-weight: 300;
    }
}

.refferal-grid-container {
    display: grid;
    gap: 22px;
    grid-gap: 22px;
    grid-template-columns: 3fr 1fr;
    margin-top: 20px;
}

.link-container {
    // height: 112px;
    background-color: rgba($color: #2e354a, $alpha: 0.8);
    border-radius: 16px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
    display: flex;
    justify-content: space-between;
    padding: 20px 18px;

    .link-wrapper {
        width: 75%;

        .link-title {
            color: #ffffff;
            font-size: 1.125em;
        }

        .link-url {
            color: #a7a7a7;
            font-family: 'Rothek SemiBold';
            font-size: 0.875em;
        }
    }

    .title-center {
        align-items: center;
        display: flex;
    }

    .link-btns-group {
        @include flex-center;
        flex-direction: column;
        margin-right: 12px;

        button {
            border-radius: 20px;
            border: none;
            box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
            color: #ffffff;
            font-family: 'Rothek SemiBold';
            font-size: 0.875em;
            padding: 6px 30px;

            &:hover {
                background-color: rgba($color: #2e354a, $alpha: 0.8);
                box-shadow: 0 1px 10px rgba(255, 255, 255, 0.2);
            }
        }

        .btn-coppy {
            background-color: #7b4fc1;
        }

        .btn-send {
            background-color: #0396bc;
            margin-top: 12px;
        }
    }
}

.users-widget {
    // height: 112px;
    align-items: flex-start;
    background-color: rgba($color: #0eaba2, $alpha: 0.8);
    background-image: linear-gradient(-63deg, #0a879d 0%, #0eaba2 100%);
    border-radius: 10px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;

    .users-count {
        color: #fffefc;
        font-size: 3em;
        line-height: normal;
    }
    .users-title {
        color: #fffefc;
        font-family: 'Rothek SemiBold';
        font-size: 1em;
    }
}

@include media-breakpoint-down(sm) {
    .refаeral-tree {
        display: grid;
        .tree-second-column {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
        .tree-third-column {
            display: flex;
            .tree-third-wrapper {
                display: block;
                .circle-wrapper {
                    flex-direction: row;
                    padding: 20px 0;
                }
            }
        }
    }
    .refferal-contaienr {
        margin: 0;
    }

    .refferal-info {
        font-size: 12px;
        padding: 0;
    }
    .link-container {
        padding: 16px;
        .link-btns-group {
            margin-right: 0;
        }
    }

    .users-widget {
        margin-top: 16px;
        padding: 16px;
    }
}
