.inbox-container {
}

.inbox-bg-color {
    background: #060b17;
}

.inbox-bg-color-read {
    background: #191d2a;
}

.inbox-title {
    color: white;
    font-size: 1.875em;
    font-weight: 700;
}

.inbox-dropdown-btn {
    background-color: #242a3c;
    border: 1px solid #3d4356;
    color: #9499ac;
    font-size: 0.8em;
    font-weight: 400;

    &:hover {
        background-color: #aa7bf6;
        border-color: #aa7bf6;
    }
    &:focus {
        background-color: #aa7bf6;
        border-color: #aa7bf6;
    }

    &.show > .ropdown-toggle {
        background-color: #aa7bf6;
        border-color: #aa7bf6;
    }
}

.inbox-item {
    background-color: #242a3c;
    border-radius: 5px 0 0 5px;
    cursor: pointer;
    display: flex;
    position: relative;

    &.active {
        background-color: #22273c;
    }

    .item-divider {
        // border-radius: 4px 0 0;
        width: 4px;

        &:first {
            border-top-left-radius: 4px;
        }
        &:last-child {
            border-bottom-left-radius: 4px;
        }
    }

    .item-content {
        display: flex;
        padding: 24px 22px 33px 35px;
        width: 100%;

        .content-primary {
            color: #ffffff;
            flex: 1;
            font-size: 1.25em;
            font-weight: 700;
        }

        .item-date {
            // font-family: 'Rothek Thin Italic';
            // font-size: 0.75em;
            align-self: center;
            color: #ffffff;
            font-weight: 400;
        }
    }
}

.inbox-content {
    background-color: #22273c;
    border-radius: 0 5px 5px 0;
    color: #bfc2cd;
    font-size: 0.875em;
    font-weight: 400;
    padding: 70px 50px;
    img {
        max-height: 100% !important;
        max-width: 100% !important;
    }
}

.inbox-fill-container {
    background: rgba(30, 35, 51, 0.7);
    box-shadow: 0 5px 38px rgba(0, 0, 0, 0.2);
    min-height: 100vh;
    padding: 50px;
}

.admin-inbox-header-col {
    align-items: flex-end;
    display: flex;
    justify-content: center;
}

.editorClassName {
    background-color: #22273c;
    min-height: 400px;
    padding: 12px;

    * span {
        color: #fff;
    }
}

.inbox-category {
    background-color: #242a3c;
    border-radius: 8px;
    display: flex;
    height: 32px;
    width: fit-content;
    .dropdown-menu.show {
        min-width: fit-content;
    }
}

.inbox-cards {
    .arrow-down {
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid #1e2333;
        bottom: -3px;
        height: 0;
        margin-left: -13px;
        position: absolute;
        transform: rotateZ(45deg);
        width: 0;

        &.arrow-bg-color-read {
            border-top: 20px solid #191d2a;
        }
        &.arrow-bg-color {
            border-top: 20px solid #060b17;
        }
    }

    .test {
        position: relative;
    }

    .arrow-up {
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid #85c134;
        bottom: -3px;
        height: 0;
        margin-left: -13px;
        position: relative;
        transform: rotateZ(45deg);
        width: 0;
    }

    .heart24-on {
        background: url('../../images/heart.png') no-repeat;
        background-size: 24px auto;
        border-radius: 0;
    }
    .heart24 a {
        color: #ffb2b2;
        font-weight: 500;
    }

    .heart24 {
        text-decoration: none;
    }
    .heart24 {
        border-radius: 50%;
        display: inline-block;
        height: 24px;
        margin-right: 4px;
        position: relative;
        top: 0;
        white-space: nowrap;
        width: 24px;
        span {
            color: #ffb2b2;
        }
    }
    .heart24 a {
        color: #ffb2b2;
        font-weight: 500;
    }
    .heart24-on {
        background: url('../../images/heart.png') no-repeat;
        background-size: 24px auto;
        border-radius: 0;
        position: absolute;
    }
    .heart24-off {
        background: url('../../images/heart-off.png') no-repeat;
        background-size: 24px auto;
        border-radius: 0;
        position: absolute;
    }
    .heart24 {
        background-position-x: 0;
        font-size: 12px;
        line-height: 20px;
        padding-bottom: 0;
        padding-left: 31px;
        padding-top: 2px;
        vertical-align: top;
    }
    .heart-parent {
        bottom: 40px;
        position: absolute;
        right: 70px;
    }
}

.modal-content {
    .btn-close {
        background-image: url('../../images/close.png');
    }
    .arrow-up {
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid #85c134;
        bottom: -3px;
        height: 0;
        margin-left: -13px;
        position: absolute;
        transform: rotateZ(45deg);
        width: 0;
    }
}

.inbox-modal.show {
    .modal-content {
        background-color: #1e2333 !important;
    }
}

@include media-breakpoint-down(sm) {
    .inbox-fill-container {
        padding: 0 20px;
        .inbox-content {
            padding: 20px 4px;
        }
    }
    .inbox-modal {
        padding-left: 0 !important;
        .modal-dialog {
            display: contents;
            .modal-body {
                padding: 0;
                .card-body {
                    div {
                        img {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .inbox-cards {
        .custom-card {
            flex: 25%;
            max-width: 30%;
        }
    }
}
