.withdrawal-actual {
    background: rgba(63, 146, 217, 0.7);
    border-radius: 10px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
    height: 100%;
    // align-items: center;
    // justify-content: center;
    display: flex;
}

.divider-actual {
    border-right: 1px solid #000;
    height: 500px;
}

.bonus-profits {
    border-radius: 10px;
    background: rgba(67, 76, 100, 0.7);
}

.withdrawal-pending-approval {
    background: rgba(123, 79, 193, 0.7);
    border-radius: 10px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
    color: #ffffff;
    padding: 23px 37px;
    font-size: 36px;
}

.withdrawal-pending-title {
    font-size: 1.125rem;
    font-weight: 100;
}

.withdrawal-bonus-title {
    font-size: 2.25rem;
}

.hold-container {
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    background: rgba(67, 76, 100, 0.7);
}

.my-invest-text {
    color: #fffefc;
    font-size: 1rem;
    white-space: nowrap;
}

.spacing-withdrawal-card {
    margin-right: 29px;
}

.hold-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 12px;
}

.withdraw-profits-text,
.my-invest-amount {
    font-size: 1.25rem;
}

.total-actual-amount {
    font-size: 2rem;
}

.withdraw-profits-text-subtitle {
    font-size: 1rem;
}

.withdraw-actual-amount {
    font-size: 1.875rem;
}

.scale-amount,
.total-actual-text {
    font-size: 1rem;
}

.pending-border {
    display: flex;
    justify-content: start;
    align-items: center;
    hr {
        width: 91px;
        transform: rotate(90deg);
    }
}

.withdraw-time-amount {
    font-size: 1.25rem;
}

.my-invest-description {
    font-size: 0.9rem;
    margin: 0;
}

.my-account-desc {
    color: #ffffff;
    font-size: 1.125em;
    font-weight: 400;
    opacity: 0.3;
}

.my-account-money {
    color: #ffffff;
    font-size: 1.125em;
    font-weight: 400;
}

div.time-wrapper {
    color: #6a7090;
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
}

.dimension {
    font-size: 0.5rem;
}

.bg-chart-color {
    div:first-child {
        background-color: #101b2d;
        border-radius: 100%;
    }
}

.info-space-withdraw {
    height: 20%;
}

@include media-breakpoint-down(md) {
    .hold-wrapper {
        margin: 8px 0;
    }
    .invest-page-container {
        margin: 0;
    }
    .deposit-widget {
        margin-top: 20px;
    }
    .navbar.nav-header {
        .mobile-logo,
        .header-date {
            display: none;
        }
    }
    .withdraw-btn {
        display: none;
    }

    .spacing-withdrawal-card {
        margin-right: 0;
    }
    .pending-border {
        display: none;
    }

    .invest-widgets-grid-container {
        margin-top: 16px;
    }
    .bg-chart-color {
        display: contents;
        justify-content: center;
    }
    .mobile-pie-chart-direction {
        gap: 6px;
        padding: 8px;
    }

    .my-invest-text {
        padding-top: 16px;
    }
    p.my-invest-description {
        margin-top: 16px;
    }
}

@include media-breakpoint-up(md) {
    .secure-withdraw-text {
        display: none;
    }
}

.disclaimer-withdrawal-styles {
    background-color: rgba(67, 76, 100, 0.7);
    border-radius: 10px;
    color: white;
    padding: 8px;
    margin-top: 16px;
}
