.console-page-container {
    .button-group {
        display: grid;
        gap: 10px;
    }
}

.console-input-wrapper {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 20px 0;
}

.console-autocomplete {
    margin-top: 20px;
}

.pagination {
    gap: 10px;

    .page-item {
        &.disabled {
            span {
                color: gray;
            }
            .page-link {
                background-color: transparent;
            }
        }

        &.active {
            .page-link {
                background-color: #7b4fc1;
            }
        }

        span {
            background-color: transparent;
        }
    }

    .page-link {
        background-color: transparent;
        border-radius: 30%;
        border: none;
        color: white;
        cursor: pointer;
        font-size: 1.35em;
        text-align: center;
    }
}
