.affiliate-grid-container {
    display: grid;
    gap: 22px;
    grid-template-columns: 3fr 1fr;
    // margin: 50px;

    &.client-container {
        display: grid;
        grid-template-columns: 1fr;
    }

    .additional-info-container {
        color: rgba($color: #ffffff, $alpha: 0.3);
        display: grid;
        font-size: 1em;
        grid-template-columns: 1.5fr 1.5fr 1fr;
        padding: 0 4rem 1rem 4rem;
    }

    .withdrawal-request-info-container {
        color: rgba($color: #ffffff, $alpha: 0.3);
        display: grid;
        font-size: 1em;
        grid-template-columns: repeat(4, 1fr);
        margin: 0 20px;
        padding: 20px 0px;

        .withdrawal-request-action {
            @include flex-center;
            gap: 10px;
        }
    }

    .employee-portfolio-container {
        display: flex;
        flex-direction: column;

        .portfolio-item {
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.3);
            color: rgba($color: #fff, $alpha: 0.7);
            display: grid;
            font-size: 1em;
            grid-template-columns: 1fr 1fr 1fr;
            margin: 10px 20px;
            padding: 10px 20px;
            place-items: center;
        }

        .portfolio-empty {
            color: rgba($color: #fff, $alpha: 0.7);
            display: grid;
            font-size: 1em;
            padding: 10px 20px;
            place-items: center;
        }
    }
}

.profit-container {
    background-image: linear-gradient(-203.98deg, #0faaa2 0%, #0a889d 100%);
    border-radius: 12px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: space-between;
    padding: 24px 20px;

    .profit-month {
        color: #fffefc;
        font-size: 0.825em;
        font-weight: 300;
    }

    .profit-amount {
        color: #fffefc;
        font-size: 2.25em;
        font-weight: 300;
    }

    .profit-text {
        color: #fffefc;
        font-family: 'Rothek SemiBold';
        font-size: 1em;
        font-weight: 400;
    }

    .profit-count {
        align-items: center;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
        display: flex;
        justify-content: space-between;
        margin-top: 8px;

        .percentage {
            color: #ffffff;
            font-family: 'Rothek SemiBold';
            font-size: 1.125em;
            font-weight: 400;

            .p-icon {
                margin-left: 4px;
                opacity: 0.6;
            }
        }

        .people {
            text-align: end;
            color: #ffffff;
            font-size: 0.75em;
            font-weight: 400;

            .primary-text {
                font-size: 1.25em;
                text-align: end;
            }
            .secondary-text {
                text-align: end;
            }
        }
    }
}

.performance-container {
    background-image: linear-gradient(-54deg, #dde3f7 0%, #ffffff 100%);
    border-radius: 10px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: space-between;
    margin-top: 20px;
    padding: 24px 20px;

    .performance-title {
        color: #555863;
        font-family: 'Rothek SemiBold';
        font-size: 1rem;
        font-weight: 400;
    }

    .performance-item {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .user-profile {
            display: flex;
            min-height: 85px;
            place-items: center;

            .user-img {
                @include flex-center;
                background-color: #000c26;
                border-radius: 50%;
                height: 38px;
                width: 38px;

                .user-txt {
                    color: #dbf9e1;
                    font-family: 'Rothek SemiBold';
                    font-size: 12px;
                    font-weight: 400;
                }
            }

            .user-info {
                margin-left: 12px;
            }
        }

        .performance-info {
            .primary-text {
                text-align: right;
            }
            .secondary-text {
                text-align: right;
            }
        }

        .primary-text {
            color: #000c26;
            font-family: 'Rothek Bold';
            font-size: 1em;
        }

        .secondary-text {
            color: rgba($color: #4c5266, $alpha: 0.5);
            font-size: 0.875em;
        }
    }
}

.affiliate-item {
    background-color: rgba($color: #2f3547, $alpha: 0.8);
    border-radius: 10px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    margin: 0 0 16px;
    position: relative;
}

.affilate-color {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    cursor: pointer;
    height: 100%;
    position: absolute;
    width: 8px;
}

.affilate-parent-color {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    cursor: pointer;
    height: 100%;
    left: -8px;
    padding: 0;
    position: absolute;
    top: 0;
    width: 8px;
}

.color-picker {
    left: 20px;
    position: absolute;
    top: 0;
    z-index: 1024;
}

.item-info-container {
    // grid-template-columns: 2fr 1.5fr 1.5fr 2fr;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 0 20px;
    padding-bottom: 10px;
    &.five-in-row {
        grid-template-columns: repeat(5, 1fr);
    }

    .user-profile {
        display: flex;
        min-height: 85px;
        place-items: center;

        .user-img {
            height: 50px;
            width: 50px;
        }

        .user-info {
            margin-left: 12px;
        }
    }

    .affilate-amount {
        @include flex-center;
        align-items: flex-end;
        flex-direction: column;
    }

    .affilate-action {
        @include flex-center;
        button {
            background-color: transparent;
            border-radius: 12px;
            border: 1px solid rgba($color: #ace994, $alpha: 0.3);
            color: rgba($color: #ffffff, $alpha: 0.3);
            font-size: 0.75em;
            font-weight: 700;
            padding: 6px 24px;

            &:hover {
                background-color: #2f3547;
                color: white;
            }
        }
    }

    .primary-text {
        color: #ffffff;
        font-family: 'Rothek Bold';
        font-size: 1em;
    }

    .secondary-text {
        color: rgba($color: #dbf9e1, $alpha: 0.3);
        font-size: 0.875em;
    }
}

.item-five-in-row {
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
}

.user-item-text {
}

.note-list-wrapper {
    padding: 20px 10px;
}

.note-item {
    flex: 1;
    margin: 10px 20px;
    color: #ffffff;
    border: 3px solid #000000;
    padding: 12px 20px;
    border-radius: 12px;
    white-space: break-spaces;
}

.additional-info-container {
    color: rgba($color: #ffffff, $alpha: 0.3);
    display: grid;
    font-size: 1em;
    grid-template-columns: 1.5fr 1.5fr 1fr;
    padding: 0 4rem 1rem 4rem;
}

.month-arrow {
    font-size: 2rem;
    cursor: pointer;
    padding: 0px 8px;
}

.withdrawal-request-info-container {
    color: rgba($color: #ffffff, $alpha: 0.3);
    display: grid;
    font-size: 1em;
    grid-template-columns: repeat(4, 1fr);
    margin: 0 20px;
    padding: 20px 0px;

    .withdrawal-request-action {
        @include flex-center;
        gap: 10px;
    }
}

.withdraw-status {
    width: 30px;
    height: 30px;
    margin-left: 12px;
}

.employee-portfolio-container {
    display: flex;
    flex-direction: column;

    .portfolio-item {
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.3);
        color: rgba($color: #fff, $alpha: 0.7);
        display: grid;
        font-size: 1em;
        grid-template-columns: 1fr 1fr 1fr;
        margin: 10px 20px;
        padding: 10px 20px;
        place-items: center;
    }

    .portfolio-empty {
        color: rgba($color: #fff, $alpha: 0.7);
        display: grid;
        font-size: 1em;
        padding: 10px 20px;
        place-items: center;
    }
}

.portfolio-empty {
    color: rgba($color: #fff, $alpha: 0.7);
    display: grid;
    font-size: 1em;
    padding: 10px 20px;
    place-items: center;
}

.employee-note {
    display: grid;
    gap: 40px;
    grid-template-columns: 3fr 1fr;
    margin: 20px;

    .note-area {
        background-color: transparent;
        border-radius: 12px;
        color: rgba($color: #fff, $alpha: 0.7);
        padding: 8px;
        width: 100%;

        &:focus {
            outline: none;
        }
    }

    .note-btn-wrapper {
        display: grid;
        place-items: center;
    }
}

// NEW
.affiliate-show-desc {
    color: #fff;
    margin: 0 10px;
    padding: 10px 0;
}

.item-info-container-client {
    box-shadow: 0 1px 0px rgba(0, 0, 0, 0.3);
    display: flex;
    margin: 0 20px;
    position: relative;

    .user-profile {
        display: flex;
        min-height: 85px;
        place-items: center;

        .user-img {
            height: 50px;
            width: 50px;
        }

        .user-info {
            margin-left: 12px;
        }
    }

    .affilate-amount {
        @include flex-center;
        align-items: flex-end;
        flex-direction: column;
    }

    .affilate-action {
        @include flex-center;
        button {
            background-color: transparent;
            border-radius: 12px;
            border: 1px solid rgba($color: #ace994, $alpha: 0.3);
            color: rgba($color: #ffffff, $alpha: 0.3);
            font-size: 0.75em;
            font-weight: 700;
            padding: 6px 24px;

            &:hover {
                background-color: #2f3547;
                color: white;
            }
        }
    }

    .primary-text {
        color: #ffffff;
        font-family: 'Rothek Bold';
        font-size: 1em;
    }

    .secondary-text {
        color: rgba($color: #dbf9e1, $alpha: 0.3);
        font-size: 0.875em;
    }
}

@include media-breakpoint-down(xs) {
    .item-info-container-client {
        margin: 0 10px;
        padding: 10px 0;
    }
}

// Client Side Affiliate Blocks by Priority for View
@include media-breakpoint-only(xs) {
    .performance-container {
        order: 1;
    }
    .profit-container {
        order: 2;
    }
    .affiliate-third-order {
        order: 3;
    }
    // Parent Affiliate item properties
    .affiliate-item {
        margin: 16px 0;
    }
}
