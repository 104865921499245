.nav-header {
    padding-top: 20px;
    background-color: none;
    height: 80px;
}

.header-right-container {
    @include flex-center;
    flex-direction: row;
    margin-right: 30px;

    .header-date {
        color: #fff;
        font-size: 1em;
    }

    .icon-item {
        margin-left: 20px;

        svg {
            color: #6b7080;
            height: 1.5em;
        }

        &.icon-item-active {
            svg {
                fill: #b92d7a;
            }
        }
    }
}
.navbar-brand {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 1em;

    img {
        width: 150px;
        height: 33px;
    }
}

.navbar .navbar-toggler {
    top: 0.25rem;
    right: 1rem;
}

.navbar .form-control {
    padding: 0.75rem 1rem;
    border-width: 0;
    border-radius: 0;
}
